import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MingleSubmitButton } from "~/components/form/MingleSubmitButton";
import { DialogWindow, ITeam } from "~/utils/api.interfaces.enums";
import { usePagesContext } from "~/routes/_pages";

declare let window: DialogWindow;
type StickySubmitBarProps = {
  activeTeam?: ITeam;
  label: string;
  submitValue: string;
  hasAdminRights?: boolean;
  isBoosted?: boolean | undefined;
  formHasChanges: boolean;
  onDiscardChange: (e: boolean) => void;
  showAnim?: boolean;
};

export const StickySubmitBar = ({
  activeTeam,
  submitValue,
  hasAdminRights,
  isBoosted,
  formHasChanges,
  label,
  onDiscardChange,
  showAnim,
}: StickySubmitBarProps) => {
  const { t } = useTranslation("translation");
  const [showBar, setShowBar] = useState<boolean>(formHasChanges);
  useEffect(() => {
    setShowBar(formHasChanges);
  }, [formHasChanges]);
  const [showButtonAnim, setShowButtonAnim] = useState<boolean | undefined>(
    showAnim
  );
  const { showSidebar } = usePagesContext();

  useEffect(() => {
    setShowButtonAnim(showAnim);
  }, [showAnim]);
  return (
    <>
      <div className="sticky-submit-bar-placeholder h-20"></div>
      <footer
        className={`fixed z-20 ${showBar ? "bottom-0" : "-bottom-[200px]"} ${
          showSidebar ? "lg:pl-72" : ""
        } transform transition-all duration-200   left-0 w-full bg-white h-20 dark:bg-black border-t border-gray-100 dark:border-gray-800 xl:gap-2 flex-row justify-between xl:justify-end items-center`}
      >
        <div
          className={`grid grid-cols-2 gap-4 pt-4 max-w-xl w-full m-auto px-3 lg:px-4 xl:px-6  `}
        >
          <button
            type="reset"
            className="btn btn-primary btn-outline btn-block"
            onClick={(e) => {
              e.preventDefault();

              onDiscardChange(false);
            }}
          >
            {t("settingsPage.discard")}
          </button>

          <MingleSubmitButton
            submitValue={submitValue}
            label={label}
            theme={
              showButtonAnim ? "btn-primary animation-pulse" : "btn-primary"
            }
            onClick={(e) => {
              setShowButtonAnim(false);
              // if (!isBoosted) {
              //   e.preventDefault();
              //   window.modalBoostLevelRights.showModal();
              //   return;
              // }
              if (!hasAdminRights) {
                e.preventDefault();
                window.modalAdminRights.showModal();
              }
              // MixPanel.track("mft_teamSettings_tap_button_saveChanges", {
              //   teamId: activeTeam?.id,
              // });
            }}
          />
        </div>
      </footer>
    </>
  );
};
